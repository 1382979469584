import Bugsnag  from '@bugsnag/js';

export const PREFIX = 'lsh';

const INTERNAL_ERROR = "INTERNAL_ERROR";
const EXTERNAL_ERROR = "EXTERNAL_ERROR";

const INTERNAL_DOMAINS = [
    "localhost",
    window?.Shopify?.shop || 'lashifyusa.myshopify.com',
    window?.Shopify?.domain,
    window?.Shopify?.domain?.replace("www.", ""),
];

const bugsnag = Bugsnag.start({
    autoTrackSessions: true,
    appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
    apiKey: 'afb7ea98e0144614ba31260a293dd6c9',
    otherOptions: {
        appType: 'frontend-minicart'
    },
    trackInlineScripts: false,
    enabledReleaseStages: [
        'production',
        'staging'
    ],
    releaseStage: import.meta.env.MODE || "production",
    user: {
        id: window?.Shopify?.customer?.id || null,
        email: window?.Shopify?.customer?.email || null,
        name: window?.Shopify?.customer?.name || null,
    },
    metadata: {
        theme: {
            id: window?.Shopify?.theme?.id || null,
            name: window?.Shopify?.theme?.name || null,
        }
    },
    maxBreadcrumbs: 10,
    maxEvents: 10,
    onError(event) {
        event?.addMetadata("customer", {
            id: window?.Shopify?.customer?.id || null,
            email: window?.Shopify?.customer?.email || null,
            name: window?.Shopify?.customer?.name || null,
        });

        (() => {
            const types = [
                ...new Set(event?.errors?.reduce((errors, error) => {
                    return [
                        ...errors,
                        ...(error?.stacktrace?.map((trace) => {
                            const file = trace?.file;
                            if (!file) {
                                return INTERNAL_ERROR;
                            }

                            if (![
                                !!INTERNAL_DOMAINS?.map((domain) => {
                                    return !![
                                        `https://${domain}`,
                                        `http://${domain}`,
                                        `//${domain}`,
                                    ]?.map((url) => {
                                        return file?.startsWith(url);
                                    })?.includes(true);
                                })?.includes(true),
                                // another conditions
                            ]?.includes(false)) {
                                return INTERNAL_ERROR;
                            }

                            return EXTERNAL_ERROR;
                        }) || []),
                    ];
                }, []) || [])
            ];

            event?.addMetadata("errorType", {
                types,
                internal: !!types?.includes(INTERNAL_ERROR) ? 1 : 0,
                external: !!types?.includes(EXTERNAL_ERROR) ? 1 : 0,
            });
        })();

        (() => {
            const searchParams = new URLSearchParams(window?.location?.search);
            if (!searchParams) {
                event?.addMetadata("kinn", {
                    isKinn: 0,
                });

                return;
            }

            if (!searchParams?.get("app")?.startsWith("kin")) {
                event?.addMetadata("kinn", {
                    isKinn: 0,
                });

                return;
            }

            event?.addMetadata("kinn", {
                isKinn: 1,
            });
        })();

        (() => {
            event?.addMetadata("errorStacks", {
                stack: event?.errors?.reduce((stacks, error) => {
                    if (error instanceof TypeError) {
                        return [
                            ...stacks,
                            error.stack,
                        ];
                    }

                    if (error instanceof SyntaxError) {
                        return [
                            ...stacks,
                            error.stack,
                        ];
                    }

                    return stacks;
                }, []) || [],
            });
        })();
    }
});

window.addEventListener('unhandledrejection', (event) => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
});

export default bugsnag;
